import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Grid, Icon } from "@material-ui/core";
import withWidth from '@material-ui/core/withWidth';
import Layout from "../components/layout";
import NotaCard from "../components/NotaCard";
import Link from 'gatsby-link';
import { autorModifier } from '../utils/tools';
import SEOAuthor from '../components/SEOAuthor';


const useStyles = makeStyles(theme => ({
    boxContenedor:{
        position:'relative',
        textAlign:"center",
        margin:"2rem",
    },
    pageControls:{
        marginTop:"1rem",
        display:"flex",
        justifyContent:"center",
    },
    boxCenterControls:{
        width:"10rem",
        position:"relative",
    },
    infoControls:{
        width:"50%",
    },
    boxPrevPage:{
        position:"absolute",
        left:"0",
    },
    boxNextPage:{
        position:"absolute",
        right:"0",
        top:"0",
    },
    gridNotaItem:{
    },
}));

function ListaNotas({notas, imgFluidDefault}) {
    const classes = useStyles();
    return(
        <Box className={classes.boxContenedor} >
            <Grid container spacing={2}>
            {
              notas.map(nota => (
                <Grid key={`listanotas-${(Math.random()*100)+1}`} item xs={12} md={4} className={classes.gridNotaItem} >
                    <NotaCard node={nota} imgFluidDefault={imgFluidDefault} />
                </Grid>
              ))
            }
            </Grid>
        </Box>
    );
}

function EncabezadoPage({titulo}) {
    const classes = useStyles();
    return(
        <Box className={classes.boxContenedor} >
            <Typography component="h1">
                <b>{titulo}</b>
            </Typography>
        </Box>
    );
}

function Paginado({currentPage, numPages, autorSlug}) {
    const classes = useStyles();
    return(
        <Box className={classes.boxContenedor} >
            <Box className={classes.pageControls}>
                <Box className={classes.boxCenterControls}>
                    {currentPage > 1
                        &&  <Box className={classes.boxPrevPage}>
                                <Link
                                    to={`/autor/${autorSlug}/${(currentPage - 1) > 1 ? currentPage - 1 : ''}`}>
                                    <Icon>keyboard_arrow_left</Icon>
                                </Link>
                            </Box>
                    }
                    <Typography className={classes.infoControls} component="span">
                        <b>{`${currentPage}/${numPages}`}</b>
                    </Typography>
                    {currentPage < numPages
                        &&  <Box className={classes.boxNextPage}>
                                <Link
                                    to={`/autor/${autorSlug}/${currentPage + 1}`}
                                >
                                    <Icon>keyboard_arrow_right</Icon>
                                </Link>
                            </Box>
                    }
                </Box>
            </Box>
        </Box>
    );
}

function InfiniteScroll({items, currentPage, numPages, autorSlug, imgFluidDefault}) {
    // cargamos de a 6 elementos
    const [ hasMore, setMore ] = useState(items.length > 6)
    const [ currentList, setCurrentList ] = useState([...items.slice(0, 6)])

    const loadEdges = () => {
      const currentLength = currentList.length
      const more = currentLength < items.length
      const nextEdges = more ? items.slice(currentLength, currentLength + 6) : []
      setMore(more)
      setCurrentList([...currentList, ...nextEdges])
    }


    useEffect(() => {
        const handleScroll = () => {
          if ( !hasMore ) return;
          if ( window.innerHeight + document.documentElement.scrollTop >= (document.documentElement.offsetHeight - 5) ){
            loadEdges();
          }
        }
        window.addEventListener('scroll', handleScroll)
      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    });

    return (
      <>
        <ListaNotas notas={currentList} imgFluidDefault={imgFluidDefault} />
        {
          !hasMore &&
            <Paginado
                autorSlug={autorSlug}
                numPages={numPages}
                currentPage={currentPage}
            />
        }
        {
          hasMore &&
            <div>Escrollea hacia abajo para cargar mas...</div>
        }
      </>
    )
  }

function Autor(props) {
    const classes = useStyles();
    const notas = props.data.allMarkdownRemark.edges.map(item => item.node);
    const imgFluidDefault = props.data.file.childImageSharp.fluid;
    const { autor, numPages, currentPage, autorSlug } = props.pageContext;

    return (
        <Layout>
            <SEOAuthor author={autor} slug={autorSlug} />
            <EncabezadoPage
                titulo={autorModifier(autor)}
            />
            {notas.length > 0 && <InfiniteScroll
                                    items={notas}
                                    imgFluidDefault={imgFluidDefault}
                                    autorSlug={autorSlug}
                                    numPages={numPages}
                                    currentPage={currentPage}
                                />
            }
            {notas.length === 0 && (
                <Box className={classes.boxContenedor} >
                    <Typography component="p">
                        <b>No hay notas por el momento para este autor.</b>
                    </Typography>
                </Box>
            )}
        </Layout>
    );
}


export const query = graphql`
    query($autor: String, $skip: Int, $limit: Int) {
        allMarkdownRemark(
            filter: {frontmatter: {author: {eq: $autor}}}
            sort: { fields: [frontmatter___date], order: DESC }
            limit: $limit
            skip: $skip
        ){
            edges{
                node{
                    frontmatter {
                        title
                        image
                        author
                        date
                        seccion
                    }
                    fields {
                        slug
                    }
                }
            }
        }
        file(sourceInstanceName: {eq: "defaults"}) {
            childImageSharp {
                fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;

export default withWidth()(Autor);
