import React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

function SEOAuthor({author, slug}) {
  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              title
              description
              keywords
              siteUrl
              twAuthor
            }
          }
          featuredImage: file(
            absolutePath: { glob: "**/src/assets/logo/POLogo.png" }
          ) {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      `}
      render={data => {
        const meta = []
        const metaTitle = `${author} - ${data.site.siteMetadata.title}`
        const ogUrl = `${data.site.siteMetadata.siteUrl}/autor/${slug}`
        const ogType = 'profile'
        const metaDescription = `Notas de ${author} en ${data.site.siteMetadata.title}`
        const image = `${ogUrl}/${data?.featuredImage?.childImageSharp?.original?.src}`
        let schema = {
          "@context": "https://schema.org",
          "@type": "Person",
          "name": author,
         }
        const schemaAsString = JSON.stringify(schema, null, 2);
        
        return(
          <Helmet
            htmlAttributes={{
              lang: "es"
            }}
            title={metaTitle}
            link={
              [
                {
                  rel:"canonical",
                  href:ogUrl,
                }
              ]
            }
            meta={
              [
                {
                  name: "description",
                  content: metaDescription
                },
                {
                  name: "keywords",
                  content: `${author}, ${data.site.siteMetadata.keywords}`
                },
                {
                  property: "og:site_name",
                  content: data.site.siteMetadata.title
                },                
                {
                  property: "og:type",
                  content: ogType
                },
                {
                  property: "og:title",
                  content: metaTitle
                },
                {
                  property: "og:description",
                  content: metaDescription
                },
                {
                  property: "og:url",
                  content: ogUrl
                },
                {
                  name: "twitter:creator",
                  content: data.site.siteMetadata.twAuthor
                },
                {
                  name: "twitter:title",
                  content: metaTitle
                },
                {
                  name: "twitter:description",
                  content: metaDescription
                }
              ]
              .concat(
                image
                ? [
                  {
                    property: "og:image",
                    content: image
                  },
                  {
                    property: "og:image:alt",
                    content: metaTitle
                  },                  
                  {
                    property: "og:image:width",
                    content: 300
                  },
                  {
                    property: "og:image:height",
                    content: 137
                  },
                  {
                    property: "og:image:url",
                    itemprop: "image",
                    content: image
                  },                      
                  {
                    property: "twitter:image",
                    content: image
                  },                      
                  {
                    name: "twitter:image:alt",
                    content: metaTitle
                  },                
                  {
                    name: "twitter:card",
                    content: "summary_large_image"
                  }
                ]
                : [
                  {
                    name: "twitter:card",
                    content: "summary"
                  }
                ]
                )
              .concat(meta)
            }
          >
            {
              schemaAsString && <script type="application/ld+json">{schemaAsString}</script>
            }
          </Helmet>
        );
      }}
    />
  );
}

export default SEOAuthor;
